import { NextPageContext } from 'next';
//import cookies from 'next-cookies';
import {
    parseCookies,
    setCookie as setCookieNookies,
    destroyCookie
} from 'nookies';

/**
 * Récupere une variable a partir des cookies
 *
 * @param name string
 * @param ctx NextPageContext
 */
export const getCookie = (name: string, ctx?: NextPageContext) => {
    let allCookies;
    if (typeof ctx != 'undefined') allCookies = parseCookies(ctx);
    else allCookies = parseCookies();
    const finalCookie = allCookies[name];
    if (finalCookie) return finalCookie;
    return null;

    /*if (typeof ctx != 'undefined') {
        const allCookies = cookies(ctx);
        const finalCookie = allCookies[name];
        if (finalCookie) return finalCookie;
    }

    if (typeof document !== 'undefined') {
        const cookieArr = document.cookie.split(';');

        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split('=');

            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }
    return null;*/
};

/**
 * Ajoute une variable au cookies
 *
 * @param name string
 * @param value string | number
 * @param expire number | undefined
 */
export const setCookie = (
    name: string,
    value: string,
    expire?: number,
    ctx?: NextPageContext
) => {
    if (!expire) expire = 365 * 3;

    if (typeof ctx == 'undefined') {
        setCookieNookies(null, name, value, {
            maxAge: expire * 24 * 60 * 60,
            path: '/'
        });
    } else {
        setCookieNookies(ctx, name, value, {
            maxAge: expire * 24 * 60 * 60,
            path: '/'
        });
    }
    /*let expires: string | number = '';
    if (expire != undefined) {
        const date = new Date();
        date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    if (typeof document !== 'undefined')
        document.cookie = name + '=' + value + expires + '; path=/';*/
    //else if typeof ctx != 'undefined')
};

export const setAllowCookies = (allow: boolean) => {
    if (allow) setCookie('allowCookies', '1', 365);
};

export const getAllowCookies = (ctx?: NextPageContext) => {
    if (typeof ctx != 'undefined') {
        const allow = getCookie('allowCookies', ctx);
        if (allow === '1') return true;
    } else {
        const allow = getCookie('allowCookies');
        if (allow === '1') return true;
    }
    return false;
};

/**
 * Supprime un cookie
 *
 * @param name string
 */
export const rmCookie = (name: string, ctx?: NextPageContext) => {
    if (typeof ctx != 'undefined') {
        destroyCookie(ctx, name, { path: '/' });
    } else destroyCookie(null, name, { path: '/' });
    //setCookie(name, 'rm', 0);
};

const Cookies = {
    getCookie,
    setCookie,
    rmCookie,
    setAllowCookies,
    getAllowCookies
};

export default Cookies;
