interface Data {
    data?: any;
    networkError?: any;
    error?: any;
}

export const resolve = async (promise: Promise<any>) => {
    const resolved: Data = {};
    try {
        resolved.data = await promise;
    } catch (e) {
        console.log(e.toString());
        resolved.networkError = e;
    }

    /*if (resolved.networkError)
        store({}).dispatch(addErrorTest('networkError a revoir'));
    else if (resolved.data && resolved.data.error)
        store({}).dispatch(addErrorTest(resolved.data.message));*/

    return resolved;
};

export default resolve;
