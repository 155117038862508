import JwtDecode from 'jwt-decode';
import { NextPageContext } from 'next';
import { getCookie, setCookie } from './cookies';
import { Router } from './i18n';
import { APIAuth } from '@api/authApi';
import getConfig from 'next/config';
import { rmCookie } from './cookies';

const { publicRuntimeConfig } = getConfig();

/**
 * Décode le token Jwt Oauth2 & vérifie si il a expiré
 *
 * @param jwtToken string
 */
export const isExpired = (jwtToken: string) => {
    const { exp } = JwtDecode(jwtToken);
    // TODO : tester expiration
    return exp * 1000 < Date.now();
};

export const getTokensStorage = (ctx?: NextPageContext) => {
    let token;
    let refreshToken;
    let ctoken;
    // Si on est sur le serveur
    if (ctx != undefined && ctx.isServer) {
        if (ctx.req && ctx.req.headers.cookie) {
            token = getCookie('passwordCredentialsToken', ctx);
            refreshToken = getCookie('refreshCredentialsToken', ctx);
            ctoken = getCookie('clientCredentialsToken', ctx);
        }
    }
    // Si on est côté client
    else {
        token = getCookie('passwordCredentialsToken');
        refreshToken = getCookie('refreshCredentialsToken');
        ctoken = getCookie('clientCredentialsToken');
        if (!token) {
            token = localStorage.getItem('passwordCredentialsToken');
            refreshToken = localStorage.getItem('refreshCredentialsToken');
        }
        if (!ctoken) {
            ctoken = localStorage.getItem('clientCredentialsToken');
        }
    }
    if (!token) {
        token = null;
        refreshToken = null;
    }
    if (!ctoken) ctoken = null;
    return { token: token, refreshToken: refreshToken, ctoken: ctoken };
};

export const setTokensStorage = (
    token: string,
    refreshToken: string,
    ctx?: NextPageContext
) => {
    if (ctx) {
        setCookie('passwordCredentialsToken', token, undefined, ctx);
        setCookie('refreshCredentialsToken', refreshToken, undefined, ctx);
    } else {
        setCookie('passwordCredentialsToken', token);
        setCookie('refreshCredentialsToken', refreshToken);
    }
};

export const setClientTokenStorage = (token: string, ctx?: NextPageContext) => {
    if (ctx) {
        setCookie('clientCredentialsToken', token, undefined, ctx);
    } else {
        setCookie('clientCredentialsToken', token);
    }
};

/**
 * La fonction sfetch sera utilisée pour fetch avec token elle gére les get, post, put et delete
 *
 * @param token string
 * @param url string
 * @param options any
 */
export const sfetch = async (token: string, url: string, options?: any) => {
    // Si le passwordCredentialsToken en storage est différent de celui passé en paramètre, on prend celui du storage
    const { token: passwordToken } = getTokensStorage();
    if (
        passwordToken &&
        passwordToken != token &&
        token != publicRuntimeConfig.DAILY_API_KEY
    )
        token = passwordToken;
    if (token) {
        if (!options) {
            options = {
                headers: {
                    accept: 'text/html;charset=UTF-8, application/json',
                    authorization: 'Bearer ' + token
                },
                method: 'GET'
            };
        } else {
            if (!options.headers)
                options.headers = { accept: '', authorization: '' };
            options.headers.accept =
                'text/html;charset=UTF-8, application/json';
            options.headers.authorization = 'Bearer ' + token;
            if (!options.method) options.method = 'GET';
        }
    }
    let returnFetch = fetch(url, options);
    let returnFetchRep = {} as any;
    await returnFetch
        .then(r => {
            returnFetchRep = r.json();
            return returnFetchRep;
        })
        .then((r: any) => {
            returnFetchRep = r;
        });
    // Si c'est un access_denied, on met à jour le client token et on retente la requête
    if (returnFetchRep.error && returnFetchRep.error == 'access_denied') {
        const { refreshToken } = getTokensStorage();
        if (refreshToken) {
            await APIAuth.refreshPasswordToken(refreshToken).then(async r => {
                if (r.data && r.data.access_token) {
                    setTokensStorage(r.data.access_token, r.data.refresh_token);

                    if (!options) {
                        options = {
                            headers: {
                                accept:
                                    'text/html;charset=UTF-8, application/json',
                                authorization: 'Bearer ' + r.data.access_token
                            },
                            method: 'GET'
                        };
                    } else {
                        if (!options.headers)
                            options.headers = { accept: '', authorization: '' };
                        options.headers.accept =
                            'text/html;charset=UTF-8, application/json';
                        options.headers.authorization =
                            'Bearer ' + r.data.access_token;
                        if (!options.method) options.method = 'GET';
                    }
                    returnFetch = fetch(url, options);
                    await returnFetch.then(r => {
                        returnFetchRep = r.json();
                        return returnFetchRep;
                    });
                } else {
                    rmCookie('passwordCredentialsToken');
                    rmCookie('refreshCredentialsToken');
                }
            });
        }
    }

    return returnFetchRep;
};
/**
 * La fonction cfetch sera utilisée pour fetch avec client token
 *
 * @param token string
 * @param url string
 * @param options any
 */
export const cfetch = async (token: string, url: string, options?: any) => {
    // Si le token en storage est différent de celui passé en paramètre, on prend celui du storage
    const { ctoken: tokenStorage } = getTokensStorage();
    if (tokenStorage && tokenStorage != token) token = tokenStorage;

    if (token) {
        if (!options) {
            options = {
                headers: {
                    accept: 'application/json',
                    authorization: 'Bearer ' + token
                },
                method: 'GET'
            };
        } else {
            if (!options.headers)
                options.headers = { accept: '', authorization: '' };
            options.headers.accept = 'application/json';
            options.headers.authorization = 'Bearer ' + token;
            if (!options.method) options.method = 'GET';
        }
    }

    let returnFetch = fetch(url, options);
    let returnFetchRep = {} as any;
    await returnFetch
        .then(r => {
            returnFetchRep = r.json();
            return returnFetchRep;
        })
        .then((r: any) => {
            returnFetchRep = r;
        });
    // Si c'est un access_denied, on met à jour le client token et on retente la requête
    if (returnFetchRep.error && returnFetchRep.error == 'access_denied') {
        await APIAuth.getClientToken().then(async r => {
            if (r.data && r.data.access_token) {
                setClientTokenStorage(r.data.access_token);
                if (!options) {
                    options = {
                        headers: {
                            accept: 'application/json',
                            authorization: 'Bearer ' + r.data.access_token
                        },
                        method: 'GET'
                    };
                } else {
                    if (!options.headers)
                        options.headers = {
                            accept: '',
                            authorization: ''
                        };
                    options.headers.accept = 'application/json';
                    options.headers.authorization =
                        'Bearer ' + r.data.access_token;
                    if (!options.method) options.method = 'GET';
                }
                returnFetch = fetch(url, options);
                await returnFetch.then(r => {
                    returnFetchRep = r.json();
                    return returnFetchRep;
                });
            }
        });
    }

    return returnFetchRep;
};

/**
 * La fonction mercureFetch sera utilisée pour fetch le hub mercure
 *
 * @param token string
 * @param url string
 * @param options any
 */
export const mercureFetch = async (
    token: string,
    url: string,
    options?: any
) => {
    if (token) {
        if (!options) {
            options = {
                headers: {
                    accept: 'application/json',
                    authorization: 'Bearer ' + token
                },
                method: 'GET'
            };
        } else {
            if (!options.headers)
                options.headers = { accept: '', authorization: '' };
            options.headers.accept = 'application/json';
            options.headers.authorization = 'Bearer ' + token;
            if (!options.method) options.method = 'GET';
        }
    }

    const returnFetch = fetch(url, options);
    let returnFetchRep = {} as any;
    await returnFetch
        .then(r => {
            returnFetchRep = r.json();
            return returnFetchRep;
        })
        .then((r: any) => {
            returnFetchRep = r;
        });

    return returnFetchRep;
};

export const auth = (ctx: NextPageContext) => {
    const { token } = getTokensStorage(ctx);

    if (ctx.req && ctx.res && !token) {
        ctx.res.writeHead(302, { Location: '/login' });
        ctx.res.end();
        return;
    }

    if (!token) {
        Router.push('/login');
    }

    return token;
};
