import resolve from '@utils/resolve';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

/**
 * Fetch un nouveau token utilisateur à partir du refreshToken
 *
 * @param refreshToken string
 *
 */
const refreshPasswordToken = async (refreshToken: string) => {
    const tosend = {
        grant_type: 'refresh_token',
        client_id: publicRuntimeConfig.API_CLIENT_ID,
        client_secret: publicRuntimeConfig.API_CLIENT_SECRET,
        refresh_token: refreshToken,
        scope: 'basic'
    };

    return await resolve(
        fetch(publicRuntimeConfig.API_AUTH_URL + '/access_token', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tosend)
        }).then(r => r.json())
    );
};

/**
 * Fetch un nouveau token client
 *
 */
const getClientToken = async () => {
    const tosend = {
        grant_type: 'client_credentials',
        client_id: publicRuntimeConfig.API_CLIENT_ID,
        client_secret: publicRuntimeConfig.API_CLIENT_SECRET,
        scope: 'basic'
    };
    return await resolve(
        fetch(publicRuntimeConfig.API_AUTH_URL + '/access_token', {
            method: 'post',
            body: JSON.stringify(tosend),
            headers: { 'Content-Type': 'application/json' }
        }).then(r => r.json())
    );
};

export const APIAuth = {
    getClientToken: getClientToken,
    refreshPasswordToken: refreshPasswordToken
};
